import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AppStore, Facebook, Instagram, PlayStore } from "./Icons";

const Footer: React.FC = () => {
  const [email, setEmail] = useState("");
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          {/* <!--Row 1 of 1--> */}
          <div className="col-12 col-md-5">
            <div className="row">
              {/* <!--Row 1 of 2--> */}
              <div className="col-5 col-md-4 ">
                <Link to="/allish/privacypolicy" className="rights-link">
                  <div className="footer-titles">Privacy Policy</div>
                </Link>
                <div className="footer-all-links">
                  <Link to="/allish#appdownload" className="footer-links">
                    My Account
                  </Link>
                  <Link to="/allish#appdownload" className="footer-links">
                    My Orders
                  </Link>
                  <Link to="/allish#appdownload" className="footer-links">
                    My Favourites
                  </Link>
                </div>
              </div>
              <div className="col-5 col-md-4 ">
                <Link to="/allish/returnpolicy" className="rights-link">
                  <div className="footer-titles">Return Policy</div>
                </Link>
                <div className="footer-all-links">
                  <Link to="/allish#key-features" className="footer-links">
                    Features
                  </Link>
                  <Link to="/allish#faq" className="footer-links">
                    FAQs
                  </Link>
                </div>
              </div>
              <div className="col-6 col-md-4 ">
                <Link to="/allish/termsandconditions" className="rights-link">
                  <div className="footer-titles text-nowrap mt-20">
                    Terms & Conditions
                  </div>
                </Link>
                <div className="footer-all-links">
                  <Link to="/allish#about-us" className="footer-links">
                    About Us
                  </Link>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (window.zE) {
                        window.zE("webWidget", "open"); // Opens the Zendesk widget
                      } else {
                        console.error("Zendesk widget not initialized");
                      }
                    }}
                    className="footer-links"
                  >
                    Contact Us
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="social d-flex align-items-center">
                  <div className="social-title">Follow Us</div>
                  <Link
                    to="https://www.instagram.com/allish.uk/?igshid=MzRlODBiNWFlZA%3D%3D"
                    className="social-media"
                    target="_blank"
                  >
                    {Instagram}
                    {/* <!-- <i className="fa-brands fa-square-instagram social-icon social-insta"></i> --> */}
                  </Link>
                  <Link
                    to="https://www.facebook.com/thefactoryfresh/"
                    className="social-media"
                    target="_blank"
                  >
                    {Facebook}
                    {/* <!-- <i className="fab fa-facebook social-icon"></i> --> */}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <!--Row 2 of 1--> */}
          <div className="col-md-5 ms-auto">
            <div className="newsletter ms-auto">
              <div className="newsletter-title">
                Subscribe to our Newsletter
              </div>
              <form className="newsletter-form">
                <div className="row">
                  <div className="col-8 col-md-9">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      style={email.length > 0 ? { color: "#000000" } : {}}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      className="newsletter-input"
                      placeholder="Enter Email address"
                    />
                  </div>
                  <div className="col-4 col-md-3 ps-0">
                    <button className="signup-btn btn" type="button">
                      Sign Up
                    </button>
                  </div>
                </div>
              </form>

              {/* <!--  --> */}
              <div className="row">
                <div className="col-6 col-md-5 col-lg-4">
                  <Link
                    to="https://apps.apple.com/gb/app/allish-uk/id6502819288"
                    target="_blank"
                  >
                    {AppStore}
                  </Link>
                </div>
                <div className="col-6 col-md-5  col-lg-4 ps-0">
                  <Link
                    to="https://play.google.com/store/apps/details?id=allish.co.uk.prod"
                    target="_blank"
                  >
                    {PlayStore}
                  </Link>
                </div>
              </div>
              {/* <!--  --> */}
            </div>
          </div>
        </div>

        {/* <!--  --> */}
        <hr className="divider" />
        <div className="row">
          <div className="col-md-7">
            <div className="rights-reserved text-center text-md-start">              
              © Allish Limited [2022-2025] All Rights Reserved
            </div>
          </div>
          <div className="col-12 col-md-5 d-flex justify-content-between">
            <Link to="/allish/termsandconditions" className="rights-link">
              <div className="rights-item text-center">Terms & Conditions</div>
            </Link>
            <Link to="/allish/privacypolicy" className="rights-link">
              <div className="rights-item">Privacy Policy</div>
            </Link>
            <Link to="/allish/returnpolicy" className="rights-link">
              <div className="rights-item">Return Policy</div>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
