import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Logo1, MobNav } from "./Icons";
import { HashLink } from 'react-router-hash-link';
//import { Helmet } from 'react-helmet';

const Header: React.FC = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setIsScrolled(true);
    } else if (window.scrollY < 70) {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`allish-header ${isScrolled ? "header-bg-green" : ""}`}
      id="allish-header">

      {/* <Helmet>
        <meta name="viewport" content="width=device-width,initial-scale=1,user-scalable=no,maximum-scale=1" />
        <meta name="Keywords" content="Allish in Your Pocket Shop Anywhere, Anytime" />
        <meta name="Description" content="Allish connects you with local ethnic stores across the UK for a seamless shopping experience" />

        <link rel="icon" type="image/x-icon" href="/images/favicon.ico" />
        <link rel="shortcut icon" type="image/ico" href="/images/favicon.ico" />
        <link rel="apple-touch-icon-precomposed" href="/images/allish.png" />

        <meta name="author" content="Allish" />
        <meta name="google" content="notranslate" />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta name="msapplication-TileImage" content="/images/allish.png" />

        <meta property="og:title" content="Allish" />
        <meta property="og:description" content="Allish connects you with local ethnic stores across the UK for a seamless shopping experience" />
        <meta property="og:image" content="/images/allish.png" />
        <meta property="og:url" content="https://allish.co.uk/allish" />
        <meta name="twitter:title" content="Allish" />
        <meta name="twitter:description" content="Allish connects you with local ethnic stores across the UK for a seamless shopping experience" />
        <meta name="twitter:image" content="/images/allish.png" />
      </Helmet> */}

      <div className="container">
        <nav className="navbar navbar-expand-md allish-nav">
          <Link className="navbar-brand" to="/allish">
            {Logo1}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNav}
            aria-controls="navbarSupportedContent"
            aria-expanded={isNavVisible}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
            {/* <!-- <i className="fas fa-bars mob-menu"></i> --> */}
            {MobNav}
          </button>
          <div
            className={`navbar-collapse custom-nav ${isNavVisible ? "show" : ""
              }`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0 header-links">
              <li className="nav-item">
                <HashLink
                  className="nav-link active"
                  aria-current="page"
                  to="/allish"
                  onClick={() => {
                    toggleNav();
                  }}
                >
                  Home
                </HashLink >
              </li>
              <li className="nav-item">
                <HashLink
                  className="nav-link"
                  to="/allish#key-features"
                  onClick={() => {
                    toggleNav();
                  }}
                >
                  Features
                </HashLink >
              </li>
              <li className="nav-item">
                <HashLink
                  className="nav-link"
                  to="/allish#about-us"
                  onClick={() => {
                    toggleNav();
                  }}
                >
                  About Us
                </HashLink >
              </li>
              <li className="nav-item">
                <HashLink
                  to="/allish#faq"
                  className="nav-link"
                  onClick={() => {
                    toggleNav();
                  }}
                >
                  FAQs
                </HashLink >
              </li>
            </ul>
            <div className="login d-none">
              <button
                type="button"
                className="btn login-signup-btn"
                id="close-button1"
                onClick={() => {
                  toggleNav();
                }}
              >
                Log In/Sign Up
              </button>
            </div>
            <button
              type="button"
              className="close-mob-nav"
              id="close-mob-nav"
              onClick={() => {
                toggleNav();
              }}
            >
              <i className="fa-solid fa-xmark close-btn"></i>
            </button>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
