import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ReturnPolicy from "./Pages/ReturnPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions";
import { Helmet } from 'react-helmet';

export const Router = () => {
  return (
    <Routes>
      {/* <Helmet>
        <meta name="viewport" content="width=device-width,initial-scale=1,user-scalable=no,maximum-scale=1" />
        <meta name="Keywords" content="Allish in Your Pocket Shop Anywhere, Anytime" />
        <meta name="Description" content="Allish connects you with local ethnic stores across the UK for a seamless shopping experience" />

        <link rel="icon" type="image/x-icon" href="/images/favicon.ico" />
        <link rel="shortcut icon" type="image/ico" href="/images/favicon.ico" />
        <link rel="apple-touch-icon-precomposed" href="/images/allish.png" />

        <meta name="author" content="Allish" />
        <meta name="google" content="notranslate" />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta name="msapplication-TileImage" content="/images/allish.png" />

        <meta property="og:title" content="Allish" />
        <meta property="og:description" content="Allish connects you with local ethnic stores across the UK for a seamless shopping experience" />
        <meta property="og:image" content="/images/allish.png" />
        <meta property="og:url" content="https://allish.co.uk/allish" />
        <meta name="twitter:title" content="Allish" />
        <meta name="twitter:description" content="Allish connects you with local ethnic stores across the UK for a seamless shopping experience" />
        <meta name="twitter:image" content="/images/allish.png" />
      </Helmet> */}

      <Route path="/" element={<Home />} />
      <Route path="/allish" element={<Home />} />
      <Route path="/allish#key-features" element={<Home />} />
      <Route path="/allish#about-us" element={<Home />} />
      <Route path="/allish#faq" element={<Home />} />

      <Route path="/allish/termsandconditions" element={<TermsAndConditions />} />
      <Route path="/allish/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="/allish/returnpolicy" element={<ReturnPolicy />} />
    </Routes>
  );
};
